@import '__importable.scss';
.container {
	.header {
		padding: 0.8rem 2.4rem 0.8rem 2.4rem;
		gap: 0.8rem;
		border-radius: 0.8rem 0.8rem 0 0;
		background-color: $lightblue-2;
		color: $blue-2;

	}

	.content {
		top: 7.9rem;
		padding: 1.2rem 1.6rem 1.2rem 1.6rem;
		gap: 1.6rem;
		border-radius: 0 0 0.8rem 0.8rem;
		background-color: $gray-1;
		display: flex;
		flex-wrap: wrap;

		.card {
			padding: 1.2rem;
			gap: 1.6rem;
			border-radius: 0.8rem;
			background: $gray-0;
			border: 0.02rem solid $gray-2;
			flex: 1 1 calc(50% - 1rem);
			justify-content: space-between;

			.button {
				border-radius: $radius-3;
				border: 0.05rem solid $blue-2;
				
				@media screen and (max-width: $media-sm) {
					padding: 0.95rem 0.4rem;
					font-size: 1rem;
				}
			}
		}
		&:only-child {
			flex: 1 1 100%;
		}
	}

	.divider {
		display: flex;
    	align-items: center;
		gap: 0.5rem;
	}
}
