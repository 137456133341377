@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	padding-bottom: 1.2rem;
}

.header {
	flex-direction: row !important;
	align-items: center;

	h3 {
		font-size: 2.4rem;
	}

	svg {
		height: 3.2rem;
		width: 3.2rem;
	}
}