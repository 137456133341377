@import '__importable.scss';
.lensPrice {
	color: $gray-4;
	font-weight: 500;
}

.title {
	height: 2.2rem;
	align-content: end;
	margin: 1.6rem 0 0.8rem;
}

.lensCopy {
	margin-top: 0.6rem;
}

.lensPackages {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
}
