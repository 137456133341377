@import '__importable.scss';
.content {
	max-width: 64rem;
	padding: 0;
}

.cardContainer {
	border: none;
	padding: unset;
}

.cardImage {
	background-color: $gray-1;
	max-height: 20rem;
	height: 20rem;

	img {
		width: 80%;
		mix-blend-mode: multiply;
		aspect-ratio: 2 / 1;
		object-fit: contain;
	}

	@media screen and (min-width: $media-sm) {
		max-height: 32rem;
		height: 32rem;

		img {
			width: 90%;
		}
	}
}

.cardSection {
	padding: 1.6rem 1.6rem 3.4rem;
	align-items: flex-start;
}

.title {
	font-size: 2.4rem;
	line-height: 2.8rem;
	padding-bottom: 1rem;
}