@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	height: inherit;
	width: inherit;
	
	.circle {
		height: 100px;
		@include skeleton-loader();
	}
	  
	.line {
		height: 8px;
		@include skeleton-loader();
	}
	  
	.rect {
		height: 120px;
		@include skeleton-loader();
	}
	  
	div {
		background-color: $white;
		position: relative;
		@include skeleton-loader();
	}
}
  
  