@import '__importable.scss';
.container {
	position: relative;
	display: flex;
	align-items: center;
	gap: 1rem;
	margin-bottom: 0.8rem;

	> div {
		position: relative;
		width: fit-content;
		top: unset;
		left: unset;
	}

	img {
		height: auto;
	}
}
