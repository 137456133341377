@import '__importable.scss';
.container {
	padding: unset;

	&--withPadding {
		padding: 2rem 1.6rem;
		@media screen and (min-width: $media-lg) {
			padding-inline: 2rem;
			padding-top: 6.4rem;
		}
	}
}

.heading {
	border-bottom: 1px solid $gray-4;
	padding-bottom: 1.6rem;
	margin-bottom: 2.4rem;
}

.collectionTrack {
	margin-top: 1rem;
}

.collectionSlider {
	overflow: visible;
	position: unset;
	height: auto;
	max-width: 30rem;
}

.accessorySlider {
	overflow: visible;
	position: unset;
	height: auto;
	max-width: 25.5rem;
}

.navigation-container {
	padding: 2.4rem 1.6rem 1.6rem 0;
	@media screen and (min-width: $media-md) {
		padding-bottom: 2.4rem;
	}
}

.prevButton,
.nextButton {
	top: 12rem;
	right: 1.8rem;
	z-index: 99;
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 100%;
	background-color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1.5px solid $gray-2;
	color: $blue;
	@include non-selectable;

	&:hover {
		background-color: $gray-2;
		transition: background-color 0.3s ease;
	}
}

.disabled {
	background-color: $gray-2;
	color: $gray-3;
	cursor: unset;
}
