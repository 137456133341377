@import '__importable.scss';
.container {
  width: fit-content;
  margin: 1rem auto 0 auto;

	a {
    color: $blue-2!important;
		font-weight: 500!important;
    font-family: var(--poppins)!important;
    text-decoration: underline!important;
    border: none!important;

    img {
      width: 0px !important;
      height: 0px !important;
    }
	}
}

.container:hover a span {
  color: $blue-1!important;
}
