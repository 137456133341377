@import '__importable.scss';
.frameSelector {
	flex: 3;

	@media screen and (min-width: $media-lg) {
		flex: 2;
	}

	@media screen and (max-width: $media-xsm) {
		flex: 2;
	}
}

.editColor {
	flex-direction: column;
	flex: 1;
	align-self: end;
	flex-shrink: 0;
	max-height: 5rem; // 48px plus 2px of border

	> button {
		max-height: 5rem;
		white-space: nowrap;
	}
}

.editContainer {
	margin-bottom: 1.6rem;
	width: 100%;

	@media screen and (min-width: $media-lg) {
		margin-bottom: 2.4rem;
	}
}
