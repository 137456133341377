@import '__importable.scss';
.breadcrumbs {
    display: flex;
    width: 100%;
    margin-left: -0.4rem;
    margin-bottom: 1.6rem;
    position: relative;
    min-height: 2.1rem;
}

.breadcrumb {
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
        width: max-content;
        padding-inline: 0.4rem;
        margin-inline: 0.1rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
        flex-grow: 0;
        text-decoration: underline;
        border-radius: $radius-2;
        transition: $transition-3;

        &:hover {
            color: $blue-1;
            background-color: $gray-2;
            transition: $transition-3;
            opacity: 1;
        }
    }

    &::after {
        content: "/";
        position: relative;
        top: 0.1rem;
        font-weight: 400;
        font-size: 1.4rem;
    }

    &:last-child {
        &::after {
            content: "";
        }
    }
}