@import '__importable.scss';
.container {
	min-width: 10rem;
	height: 3rem;
	margin: 0.8rem 0;
}

.loader {
	height: 3rem;
	width: 12rem;
	@include skeleton-loader();
}
