@import '__importable.scss';
.container {
	margin: 2.4rem 0 1.6rem;
}

.listItem {
	display: flex;
	align-items: center;
	margin: 3.2rem 0;

	p {
		margin-bottom: 0.5rem;
		max-width: 30rem;
	}
}

.descriptionContainer {
	margin-left: 1.6rem;
}
